import clsx from 'clsx';
import classes from './styles.module.css';

export type ButtonType = 'primary' | 'secondary' | undefined;

interface ButtonProps {
  variant?: ButtonType;
  text: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isAnimate?: boolean;
}

export default function Button({
  variant = 'primary',
  text,
  onClick,
  style,
  iconLeft,
  iconRight,
  isAnimate = false,
}: ButtonProps) {
  return (
    <button onClick={onClick} style={style} className={clsx(classes.btn, classes[variant])}>
      {iconLeft ? iconLeft : null}
      <span>{text}</span>
      {iconRight ? (
        <div className={clsx({ [classes.iconAnimate]: isAnimate })}>
          {iconRight}
        </div>
      ) : null}
    </button>
  );
}
