import Image from 'next/image'
import clsx from 'clsx'
import {motion} from 'framer-motion'
import Link from 'next/link'

import {allInOneSolutionData} from 'v1/content/home-page'

import SmallLogoDark from 'v1/assets/icons/small-logo-dark.svg'
import ArrowRight from 'v1/assets/icons/arrow-right.svg'
import ChevronRight from 'v1/assets/icons/chevron-right.svg'
import user1 from 'v1/assets/icons/user-chip-icons/user-1.svg'
import user2 from 'v1/assets/icons/user-chip-icons/user-2.svg'
import user3 from 'v1/assets/icons/user-chip-icons/user-3.svg'
import classes from './styles.module.css'

export default function AllInOneSolution() {
  return (
    <section className={classes.allInOneContainer}>
      <h2 className={clsx("h2-v1 text-center", classes.heading)}>
        {allInOneSolutionData?.title1} {' '}{allInOneSolutionData?.title2}
      </h2>
      <div className={classes.multiCardContainer}>
        {allInOneSolutionData?.multiProductsCardList?.map(productDetails => (
          <MultiProductCard details={productDetails} key={productDetails?.key} />
        ))}
      </div>
      <SingleProductCard details={allInOneSolutionData.singleProductCardData} />
    </section>
  )
}

function MultiProductCard({details}: {details: any}) {
  const isZenIt = details?.key === 'zenit'
  const listArrowVariants = {
    hidden: {x: 0}, // Initial position
    hover: {x: 8}, // Move 8px to the right on hover
  }

  return (
    <div className={clsx(classes.multiProductCard, classes[`${details?.key}Border`])}>
      <div
        className={clsx(
          classes.multiCardHeader,
          classes[`${details?.key}Border`],
          classes[`${details?.key}BgColor`],
        )}
      >
        <Image src={details?.icon} width={40} alt="company-logo" />
        <div className={classes.headerDetails}>
          <h4 className="h4-v1">{details?.title}</h4>
          <p className="paragraph3-v1">{details?.subTitle}</p>
        </div>
      </div>
      <ul>
        {details?.list?.map((list: any, index: number) => (
          <Link href={list?.link} key={index} style={{cursor: list?.link ? 'pointer' : 'auto'}}>
            <li
              className={clsx(
                classes.productList,
                classes[`${details?.key}Border`],
                isZenIt && classes.productListHover,
              )}
            >
              <div className={classes.productListText}>
                <span className={classes.listCompanyIcon}>
                  <Image src={SmallLogoDark} width={16} alt="company-logo" />
                </span>
                <span className="h8-v1">{list?.text}</span>
              </div>
              {isZenIt ? (
                <motion.div
                  className={classes.listArrow}
                  variants={listArrowVariants}
                  initial="hidden"
                  whileHover="hover"
                  transition={{type: 'tween', duration: 0.3}}
                >
                  <Image src={ArrowRight} width={16} alt="arrow" />
                </motion.div>
              ) : null}{' '}
            </li>
          </Link>
        ))}
      </ul>
      <div className={classes.footerDetails}>
        <h5 className="h7-v1">{details?.footerData?.title}</h5>
        {details?.footerData?.content ? (
          <p className={classes.footerContent}>{details?.footerData?.content}</p>
        ) : null}
        {isZenIt ? (
          <Link href={details?.footerData?.knowMoreLink} className={classes.knowMoreBtn}>
            <span>Know more</span>
            <Image src={ChevronRight} width={12} alt="arrow-icon" />
          </Link>
        ) : null}
      </div>
    </div>
  )
}

function SingleProductCard({details}: {details: any}) {
  return (
    <div className={classes.singleProductCard}>
      <div className={clsx(classes.multiCardHeader, classes.singleCardHeader)}>
        <Image src={details?.icon} width={40} alt="company-logo" />
        <div className={classes.headerDetails}>
          <h4 className="h4-v1">{details?.title}</h4>
          <p className="paragraph3-v1">{details?.subTitle}</p>
        </div>
      </div>
      <div className={classes.singleCardListContainer}>
        {details?.list?.map((list: any, index: number) => (
          <div key={index} className={classes.singleCardList}>
            <span className={classes.listCompanyIcon}>
              <Image src={SmallLogoDark} width={16} alt="company-logo" />
            </span>
            <span className="h8-v1">{list?.text}</span>
          </div>
        ))}
      </div>
      <div className={classes.multiUserChipContainer}>
        <button className={classes.userChipInnerContainer}>
          <div className={classes.multiUserChipCard}>
            <Image src={user1} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user2} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user3} width={20} alt="user-icon" />
          </div>
          <span className="h8-v1">{details?.footerData?.title}</span>
        </button>
      </div>
    </div>
  )
}
