import SmallLogo from 'v1/assets/icons/small-logo-white.svg'

import beamLogo from 'v1/assets/icons/home-page/hero/beam-logo.svg'
import brighterAILogo from 'v1/assets/icons/home-page/hero/brighter-ali-logo.svg'
import carousellLogo from 'v1/assets/icons/home-page/hero/carousell-logo.svg'
import caveLogo from 'v1/assets/icons/home-page/hero/cave-logo.svg'
import cybusLogo from 'v1/assets/icons/home-page/hero/cybus-logo.svg'
import cycleLogo from 'v1/assets/icons/home-page/hero/cycle-logo.svg'
import multiplierLogo from 'v1/assets/icons/home-page/hero/multiplier-logo.svg'
import oodjiLogo from 'v1/assets/icons/home-page/hero/oodji-logo.svg'
import OptilyzLogo from 'v1/assets/icons/home-page/hero/optilyz-logo.svg'
import quincusLogo from 'v1/assets/icons/home-page/hero/quincus-logo.svg'
// import saperedLogo from 'v1/assets/icons/home-page/hero/sapered-logo.svg'
import vytalLogo from 'v1/assets/icons/home-page/hero/vytal-logo.svg'

import ZenCoreIcon from 'v1/assets/icons/home-page/Zen CORE.svg'
import ZenHrIcon from 'v1/assets/icons/home-page/Zen HR.svg'
import ZenItIcon from 'v1/assets/icons/home-page/Zen IT.svg'
import ZenExIcon from 'v1/assets/icons/home-page/Zen EX.svg'


import ZenCoreED from 'v1/assets/icons/home-page/zen-core-ed-image.webp'
import ZenCoreDM from 'v1/assets/icons/home-page/zen-core-dm-image.webp'
import ZenCoreWA from 'v1/assets/icons/home-page/zen-core-wa-image.webp'
import ZenCoreRA from 'v1/assets/icons/home-page/zen-core-ra-image.webp'
import ZenCorePP from 'v1/assets/icons/home-page/zen-core-pp-image.webp'

import ZenHrHRIS from 'v1/assets/icons/home-page/zen-hr-chr-image.webp'
import ZenHrEP from 'v1/assets/icons/home-page/zen-hr-ep-image.webp'
import ZenHrPms from 'v1/assets/icons/home-page/zen-hr-pms-image.webp'
import ZenHrTA from 'v1/assets/icons/home-page/zen-hr-ta-image.webp'
import ZenHrHrc from 'v1/assets/icons/home-page/zen-hr-hrc-image.webp'

import ZenItDM from 'v1/assets/icons/home-page/zen-it-dm-image.webp'
import ZenItImgp from 'v1/assets/icons/home-page/zen-it-imgp-image.webp'
import ZenItAsm from 'v1/assets/icons/home-page/zen-it-asm-image.webp'
import ZenItIam from 'v1/assets/icons/home-page/zen-it-iam-image.webp'
import ZenItIth from 'v1/assets/icons/home-page/zen-it-ith-image.webp'

import ZenExAiHr from 'v1/assets/icons/home-page/zen-ex-ai-hr-image.webp'
import ZenExAiIt from 'v1/assets/icons/home-page/zen-ex-ai-it-image.webp'
import ZenExZenAi from 'v1/assets/icons/home-page/zen-ex-zen-ai-image.webp'

import ZenPayrollPP from 'v1/assets/icons/home-page/zen-payroll-pp-image.webp'
import ZenPayrollUsp from 'v1/assets/icons/home-page/zen-payroll-usp-image.webp'
import ZenPayrollGcm from 'v1/assets/icons/home-page/zen-payroll-gcm-image.webp'
import ZenPayrollGp from 'v1/assets/icons/home-page/zen-payroll-gp-image.webp'
import ZenPayrollEor from 'v1/assets/icons/home-page/zen-payroll-eor-image.webp'

import {
  ZEN_IT_HOME,
  MOBILE_DEVICE_MANAGEMENT,
  INVENTORY_MANAGEMENT,
  APP_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  IT_HELPDESK,
} from 'v1/utils/getters'

export const heroData = {
  heading: 'AI platform for modern HR & IT teams',
  subHeading:
    'Magically simplify the way you manage your HR & IT- from payroll, benefit & expenses, to employee devices and apps',
}

export const heroMarqueeImages = [
  brighterAILogo,
  cycleLogo,
  OptilyzLogo,
  oodjiLogo,
  caveLogo,
  beamLogo,
  multiplierLogo,
  quincusLogo,
  carousellLogo,
  cybusLogo,
  // saperedLogo,
  vytalLogo,
]

export const allInOneSolutionData = {
  title1: 'Your all-in-one HR & IT',
  title2: 'solution powered by AI',
  multiProductsCardList: [
    {
      key: 'zenhr',
      title: 'Zen HR',
      icon: ZenHrIcon,
      subTitle: 'Built with a global-first approach',
      list: [
        {text: 'Core HR', link: ''},
        {text: 'Time & Attendance', link: ''},
        {text: 'Performance Management & Survey', link: ''},
        {text: 'Expense Management', link: ''},
        {text: 'Payroll', link: ''},
      ],
      footerData: {
        title: 'Empower your HR team',
        knowMoreLink: '',
      },
    },
    {
      key: 'zenit',
      title: 'Zen IT',
      icon: ZenItIcon,
      subTitle: 'Strengthen security and automate busy work',
      list: [
        {text: 'Mobile Device Management', link: MOBILE_DEVICE_MANAGEMENT},
        {text: 'Inventory Management', link: INVENTORY_MANAGEMENT},
        {text: 'App & SaaS Management', link: APP_MANAGEMENT},
        {text: 'Identity & Access Management', link: IDENTITY_MANAGEMENT},
        {text: 'IT Helpdesk', link: IT_HELPDESK},
      ],
      footerData: {
        title: 'Empower your IT team',
        knowMoreLink: ZEN_IT_HOME,
      },
    },
    {
      key: 'zenex',
      title: 'Zen EX',
      icon: ZenExIcon,
      subTitle: 'AI powered Employee Experience',
      list: [
        {text: 'AI\u00A0\u00A0HR companion on Slack and Teams', link: ''},
        {text: 'AI\u00A0\u00A0IT companion on Slack and Teams', link: ''},
        {text: 'AI\u00A0\u00A0Knowledge Management', link: ''},
      ],
      footerData: {
        title: 'Need to find or update something?',
        content: 'Just ask our Slack bot. It’s like your company’s first head of HR/IT',
        knowMoreLink: '',
      },
    },
  ],
  singleProductCardData: {
    title: 'Zen Core',
    icon: ZenCoreIcon,
    subTitle: 'AI powered Automation for HR & IT',
    list: [
      {text: 'Employee Database'},
      {text: 'Document Management'},
      {text: 'Workflow Automation'},
      {text: 'Reports & Analytics'},
      {text: 'Policies & Permissions'},
    ],
    footerData: {
      title: 'Zen Employee Experience',
      link: '',
    },
  },
}

export const zenCoreData = {
  topTitle: 'AI powered Automation for HR & IT',
  title: 'Zen Core',
  titleLogo: ZenCoreIcon,
  desc: 'Experience the platform that saves your time, not takes it.',
  slides: [
    {
      btnDetails: {
        title: 'Employee Database',
        boldDesc: 'Store and manage all your people’s data securely in one unified platform.',
        desc: 'Seamlessly collect, organize, and harness data sets to supercharge your workflow – say goodbye to spreadsheets!',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreED,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Document Management',
        boldDesc: 'Manage Document with Ease',
        desc: `Easily manage your documents with ZenAdmin. Store them directly or link to your preferred tools, E-sign them – you're in complete control.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreDM,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Workflow Automation',
        boldDesc: 'Optimize And Automate Your People Processes',
        desc: 'Master your people processes with ease. Create, automate, and collaborate on workflows like onboarding, off-boarding, apps provisioning, compensation changes, training, and more. Use ZenAdmin templates or start from scratch – the choice is yours.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreWA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Reports & Analytics',
        boldDesc: 'Instant Data Export with ZenAdmin',
        desc: 'Export your people data to a spreadsheet in as little as two clicks. The fastest way to analyze your data in your preferred tools. Look no further.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreRA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Policies & Permissions',
        boldDesc: 'Securely Automate Employee Access',
        desc: 'Automate what employees can see and do with robust security. Enforce your company policies automatically for seamless compliance.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCorePP,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenHRData = {
  topTitle: 'AI powered HR Management',
  title: 'Zen HR',
  titleLogo: ZenHrIcon,
  desc: 'The HRIS platform built with a global-first approach',
  slides: [
    {
      btnDetails: {
        title: 'Core HR',
        boldDesc: 'Achieve Operational Excellence with Zen HRIS',
        desc: 'Streamline operations and elevate the employee experience with ZenAdmin HRIS. A system that admins, executives, HR, and employees will love to use.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrHRIS,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Time & Attendance',
        boldDesc: 'Schedule Shifts and Track Working Hours',
        desc: 'Effortless time tracking that gives you more time back. Improve productivity levels and reduce errors in payroll processing',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrTA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'HR Concierge',
        boldDesc: 'Global HR CONCIERGE - Admin services that fit you',
        desc: `Leave behind your compliance busy work. ZenAdmin’s HR Concierge technology and services automate time-intensive compliance tasks and provide access to HR experts, allowing you to stay compliant and focus on growing your business.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrHrc,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Employee Portal',
        boldDesc: 'A Workspace for Your Team',
        desc: `Empower your team with access to check time off balances, view payslips, update personal information, and more.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrEP,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Performance Management & Survey',
        boldDesc: 'Effortlessly Build High-Performing Teams',
        desc: `Performance Management powered by your employee data. Easily set up and maintain your performance process throughout the employee lifecycle.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrPms,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenITData = {
  topTitle: 'AI powered IT Management',
  title: 'Zen IT',
  titleLogo: ZenItIcon,
  desc: 'Strengthen security and automate busy work',
  slides: [
    {
      btnDetails: {
        title: 'Mobile Device Management',
        boldDesc: 'One Solution to Secure You Devices',
        desc: 'Strengthen security and streamline device management with MDM that provides real-time access to native user and device data.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItDM,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Inventory Management & Global Procurement',
        boldDesc: 'Global Logistics, Storage, Procurement and more.',
        desc: `ZenAdmin takes care of the heavy lifting of fleet management, too. From repair, to storage, to checking your machines. One click and we take care of the rest.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItImgp,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'App & SaaS Management',
        boldDesc: 'One SaaS to Manage All SaaS and Access',
        desc: 'Transform from SaaS chaos to control. ZenAdmin combines an HRIS and IdP into one platform, providing enhanced security with granular access controls across the user lifecycle. Discover and manage every app, optimize costs, and unify SaaS contracts seamlessly.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItAsm,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Identity & Access Management',
        boldDesc: 'Unified Access Management for the Modern Enterprise',
        desc: 'One platform that consolidates access control throughout the entire user lifecycle, covering federated, unfederated, and shadow apps. Finally, a comprehensive solution for modern enterprises.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItIam,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'IT Helpdesk',
        boldDesc: 'Seamless IT Helpdesk Support',
        desc: `Choosing the right IT support is critical. ZenAdmin's IT Global Helpdesk offers reliable, efficient solutions, 24/7 tailored to your needs. Experience top-notch IT support that streamlines your operations at a fraction of the cost.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItIth,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenEXData = {
  topTitle: 'AI powered Employee Experience',
  title: 'Zen EX',
  titleLogo: ZenExIcon,
  desc: 'Need to find or update something? Just ask our Slack bot. It’s like your company’s first head of HR/IT',
  slides: [
    {
      btnDetails: {
        title: 'AI HR companion on Slack and Teams',
        boldDesc: 'Seamless Information Access in Slack & Teams  powered by AI',
        desc: 'Find any information across your work apps without leaving Slack. When you ask a question, ZenAdmin’s AI bot analyzes all your connected work apps, demand to deliver the right answer instantly.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenExAiHr,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'AI IT companion on Slack and Teams',
        boldDesc: 'Seamless Information Access in Slack & Teams  powered by AI',
        desc: `Find any IT information across your work apps without leaving Slack. When you ask a question, ZenAdmin’s AI bot analyzes all your connected work apps, demand to deliver the right answer instantly.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenExAiIt,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Zen AI',
        boldDesc: 'Maximize Efficiency and Focus with ZenAdmin AIs',
        desc: 'Boost efficiency and concentrate on what truly matters with ZenAdmin AI.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenExZenAi,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenPayrollData = {
  headingData: {
    topTitle: 'Powerful global all-in-one solution',
    title: 'Zen Payroll',
    subTitleText1: `No more need of 10 different providers and tools, ZenAdmin finally solve it all.`,
    subTitleText2: ' Pay employee and contractor your way.',
  },
  tabs: [
    {
      key: 'preliminaryPayroll',
      title: 'Preliminary Payroll',
      subTitle: 'Instant Access to Your Payroll Data',
      paragraph:
        'Whether you work with an accountancy firm or a payroll provider, ZenAdmin grants you instant access to the data you need at all times. Effortlessly manage and retrieve your payroll information whenever you need it.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollPP,
    },
    {
      key: 'uSPayroll',
      title: 'US Payroll',
      subTitle: 'Payroll So Powerful It Can Run Itself',
      paragraph:
        'ZenAdmin’s automated payroll system is so powerful, it practically runs itself. Simplify your payroll processes and experience effortless management.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollUsp,
    },
    {
      key: 'globalPayroll',
      title: 'Global Payroll',
      subTitle: 'Fast and Accurate Global Payroll for Peace of Mind',
      paragraph:
        'ZenAdmin offers the ease of an all-in-one solution. Experience fast, accurate global payroll services with the only native service for international entities, delivering peace of mind.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollGp,
    },
    {
      key: 'globalCM',
      title: 'Global Contractor Management',
      subTitle: 'Seamlessly Pay Contractors Worldwide',
      paragraph:
        'Hire and pay contractors anywhere in the world with ease. Elevate your contractor experience and streamline compliance effortlessly.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollGcm,
    },
    {
      key: 'employerOfRecord',
      title: 'Employer Of Record',
      subTitle: 'Effortlessly Hire and Pay Global Employees',
      paragraph:
        'Get started at lightning speed. ZenAdmin takes care of the complexities of international EOR entities and compliance, seamlessly integrating your global workforce and tools into one unified HR system.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollEor,
    },
  ],
}
